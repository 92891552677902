@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  width: 100%;
  background-color: black;
}
* {
  box-sizing: border-box;
  font-weight: 500 !important;
}

h1,
h2 {
  font-family: "Poppins", sans-serif;
  margin: 1.25rem;
  /* added to to fix header above the footer on landing pg.*/
}

h3 {

  font-family: 'DM Sans', sans-serif;
}

.grad-text {
    
  background: linear-gradient(-45deg, #ee7752 20%, #e73c7e 40%, #23a6d5 60%, #23d5ab 80%);
  background-size: 200% auto;
  
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  -webkit-animation: shine 6s ease infinite;
  
          animation: shine 6s ease infinite;
}

.navbar {
  background-color: rgb(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  color: rgb(207, 207, 207);
}

.nav-links {
  margin-right: 3rem;
}

.nav-toggles {
  color: white !important;
}

.nav-toggles:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: 0.2s ease;
}

@media only screen and (max-width: 786px)
{
    .nav-name {
        display: none;
    }

  .nav-links {
    margin-right: unset;
  }

  .toggler {
    border: none;
  }
}

.landing {
  height: 100vh;
  background-color: black;
  color: white;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*added to prevent user select on the main hero/landing viewport */
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
}

.topline {
    padding-top: 25vh;
    font-size: 2.5vmax;
}

.mainhead {
  font-size: 8vmax;
  background: linear-gradient(  
    -45deg,
    #ee7752 20%,
    #e73c7e 40%,
    #23a6d5 60%,
    #23d5ab 80%
  );
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  -webkit-animation: shine 6s ease infinite;

          animation: shine 6s ease infinite;
}

.btn {
    border: 2px solid white;
}

@-webkit-keyframes shine {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes shine {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.date {
  font-size: 3vmax;
}

.register {
    height: 100vh;
    background-color: black;
    color: white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /*added to prevent user select on the main hero/landing viewport */
  }
  
  .particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
  }
  
  .register-title{
    font-size: 3vmax;
    color: aliceblue;
  }

  .mainhead {
    font-size: 8vmax;
    background: linear-gradient(
      -45deg,
      #ee7752 20%,
      #e73c7e 40%,
      #23a6d5 60%,
      #23d5ab 80%
    );
    background-size: 200% auto;
  
    color: #000;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  
    -webkit-animation: shine 6s ease infinite;
  
            animation: shine 6s ease infinite;
  }
  
  .btn {
      border: 2px solid white;
  }
  
  @-webkit-keyframes shine {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  @keyframes shine {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .date {
    font-size: 3vmax;
  }
  
#tale{
    line-height: 200%;
    text-align: center;
}

.tale {
    height: 100%;
    padding: 3rem 0;
}

.daed-img {
    max-width: 35vmax;
    border-radius: 20px;
}

.para {
    color: white;
    font-size: 1.5rem;
}

.header1 {
    position: relative;
    overflow: hidden;
}

.header1::before {
    position: absolute;
    top: 50%;
    right: 30%;
    overflow: hidden;
    width: 70rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    -webkit-animation: gradient 5s ease infinite;
            animation: gradient 5s ease infinite;
}

@-webkit-keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  

@media only screen and (max-width: 1200px)
{
    .header1 {
        text-align: center !important;
    }
    .header1::before {
        display: none;
    }
}
#ready{
    line-height: 200%;
}

.ready {
    background-color: black;
    color: white;
}

.imaze {
    width: 100%;
}

.header {
    position: relative;
    overflow: hidden;
}

.header::after {
    position: absolute;
    top: 80%;
    left: 30%;
    overflow: hidden;
    width: 70rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    -webkit-animation: gradient 5s ease infinite;
            animation: gradient 5s ease infinite;
}

@-webkit-keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  

@media only screen and (max-width: 1200px)
{
  .para {
    text-align: center !important;
  }
    .header {
      text-align: center !important;
    }
    .header::after {
        display: none;
    }
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 400px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 20px;
}

.flip-card-front {
  background-color: #000000;
  color: white;
  padding: 3rem;
}

.flip-card-back {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 300% 300%;
  -webkit-animation: gradient 5s ease infinite;
          animation: gradient 5s ease infinite;
  color: white;
  padding: 3rem;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  font-family: 'DM Sans', sans-serif;
}

.header2 {
  position: relative;
  overflow: hidden;
}

.header2::before {
  position: absolute;
  top: 75%;
  right:15%;
  overflow: hidden;
  width: 80rem;
  height: 3px;
  content: '\a0';
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 200% 200%;
  -webkit-animation: gradient 5s ease infinite;
          animation: gradient 5s ease infinite;
}

.headeriti {
  position: relative;
  overflow: hidden;
}

.headeriti::before {
  position: absolute;
  top: 75%;
  right:48%;
  overflow: hidden;
  width: 80rem;
  height: 3px;
  content: '\a0';
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 200% 200%;
  -webkit-animation: gradient 5s ease infinite;
          animation: gradient 5s ease infinite;
}

@media only screen and (max-width: 1200px)
{
  .header2 {
    text-align: center !important;
  }
  .header2::before {
      display: none;
  }
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.mainhead1 {
  
  background: linear-gradient(-45deg, #ee7752 20%, #e73c7e 40%, #23a6d5 60%, #23d5ab 80%);
  background-size: 200% auto;
  
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  -webkit-animation: shine 6s ease infinite;
  
          animation: shine 6s ease infinite;
}

.svg-img {
position: absolute;
bottom: -10%;
right: 0;
}


.svg-img1 {
position: absolute;
bottom: -10%;
right: -15%;
}

@-webkit-keyframes shine {
  0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
}

@keyframes shine {
  0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
}


@media only screen and (max-width: 786px)
{
.flip-card:active .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

.flip-card-back h4 {
  font-size: 1.2rem;
}

.flip-card-back {
  padding: 2rem;
}
}
.text-gold {
    
    background: linear-gradient(-45deg, #fff 20%, #0084ff 40%, #fff 60%, #0084ff 80%);
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: shine 8s ease infinite;
            animation: shine 8s ease infinite;
    font-size: 4rem;
  }

.text-silver {
    background: linear-gradient(-45deg, #fff 20%, #777777 40%, #fff 60%, #777777 80%);
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: shine 8s ease infinite;
            animation: shine 8s ease infinite;
    font-size: 4rem;
}
  
.text-bronze {
    background: linear-gradient(-45deg, #fff 10%, #ffee00 40%, #fff 60%, #ffee00 80%);
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: shine 8s ease infinite;
            animation: shine 8s ease infinite;
    font-size: 4rem;
}

.text-bronze-real {
  background: linear-gradient(-45deg, #fff 10%, #CD7F32 40%, #fff 60%, #CD7F32 80%);
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: shine 8s ease infinite;
          animation: shine 8s ease infinite;
  font-size: 4rem;
}

.text-partner {
  color: #fff;
  font-size: 4rem;
}

.spon-col {
  
}

.spon-logos {
  /* width: 100%; */
  height: 200px;
  border-radius: 1rem;
  margin: 1rem 0;
}

.spon-logos:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.2s ease;
}

.sponsors h2 {
  color: #fff;
}

.header3 {
    position: relative;
    overflow: hidden;
  }
  
  .header3::before {
    position: absolute;
    top: 50%;
    left:20%;
    overflow: hidden;
    width: 70rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    -webkit-animation: gradient 5s ease infinite;
            animation: gradient 5s ease infinite;
  }
  
  @media only screen and (max-width: 1200px)
  {
    .header3 {
        text-align: center !important;
    }
    .header3::before {
        display: none;
    }
  }

  @media only screen and (max-width: 786px) {
    .spon-logos {
      height: 150px;
    }
  }
  
.cp {
  color: white;
}

/* .com-logo {
  width: 12rem;
} */

#comlogo {
  height: 150px;
  border-radius: 1rem;
  margin: 1rem 0;
}

.grad-header {
    position: relative;
    overflow: hidden;
  }
  
  .grad-header::before {
    position: absolute;
    top: 50%;
    right:41%;
    overflow: hidden;
    width: 50rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    -webkit-animation: gradient 5s ease infinite;
            animation: gradient 5s ease infinite;
  }
  
  @media only screen and (max-width: 1200px)
  {
    .grad-header {
        text-align: center !important;
    }
    .grad-header::before {
        display: none;
    }
  }
  
.oc-img {
    border-radius: 100%;
}
#oclogo{
    height: 150px;
}

.octitle {
  font-size: 4vmax;
  background: linear-gradient(
    -45deg,
    #ee7752 20%,
    #e73c7e 40%,
    #23a6d5 60%,
    #23d5ab 80%
  );
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  -webkit-animation: shine 6s ease infinite;

          animation: shine 6s ease infinite;
}

/* .speak-text {
    position: relative;
    overflow: hidden;
  }
  
  .speak-text::before {
    position: absolute;
    top: 50%;
    right:20%;
    overflow: hidden;
    width: 70rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    animation: gradient 5s ease infinite;
  }
  
  @media only screen and (max-width: 1200px)
  {
    .speak-text {
        text-align: center !important;
    }
    .speak-text::before {
        display: none;
    }
  }
  */


.speak-text {
    position: relative;
    overflow: hidden;
  }
  
  .speak-text::after {
    position: absolute;
    top: 50%;
    left:20%;
    overflow: hidden;
    width: 70rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    -webkit-animation: gradient 5s ease infinite;
            animation: gradient 5s ease infinite;
  }
  
@media only screen and (max-width: 1200px)
{
  .speak-text{
    text-align: center !important;
  }
  .speak-text::after {
    display: none;
  }
}


.speak-col {
  width: 10%;
}

.speak-logos {
  width: 230px;
  border-radius: 3rem;
}

.speak-logos:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.2s ease;
}

.speakers h2 {
  color: #fff;
}

.speakers h5 {
  color: #fff;
}
.header3 {
    position: relative;
    overflow: hidden;
  }
  
  .header3::before {
    position: absolute;
    top: 50%;
    left:20%;
    overflow: hidden;
    width: 70rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    -webkit-animation: gradient 5s ease infinite;
            animation: gradient 5s ease infinite;
  }
  
  @media only screen and (max-width: 1200px)
  {
    .header3 {
        text-align: center !important;
    }
    .header3::before {
        display: none;
    }
  }
  
.prizes-text {
    position: relative;
    overflow: hidden;
  }

  .tba {
      font-size: 4rem;
      padding: 4rem;
  }
  
  .prizes-text::after {
    position: absolute;
    top: 50%;
    right:12%;
    overflow: hidden;
    width: 70rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    -webkit-animation: gradient 5s ease infinite;
            animation: gradient 5s ease infinite;
  }
  
  @media only screen and (max-width: 1200px)
  {
    .prizes-text {
        text-align: center !important;
    }
    .prizes-text::after {
        display: none;
    }
    .tba {
      text-align: center;
      font-size: 3.5rem;
      padding: 0;
    }
  }
  
  .flip-card-prize {
    background-color: transparent;
    width: 100%;
    height: 400px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  .flip-card-inner-prize {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: left;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }
  
  .flip-card-prize:hover .flip-card-inner-prize {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .flip-card-front-prize, .flip-card-back-prize, .flip-card-front-sawo, .flip-card-front-raahee {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 20px;
  }
  
  .flip-card-front-prize {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 300% 300%;
    -webkit-animation: gradient 8s ease infinite;
            animation: gradient 8s ease infinite;
    color: white;
    padding: 1rem;
  }

  .flip-card-front-sawo {
    background: #fcde50;
    background-size: 300% 300%;
    -webkit-animation: gradient 8s ease infinite;
            animation: gradient 8s ease infinite;
    color: white;
    padding: 1rem;
  }
  
  .flip-card-front-raahee {
    background: linear-gradient(135deg, rgba(56,29,89,1) 35%, rgba(252,139,139,1) 71%, rgba(252,195,112,1) 100%);
    background-size: 300% 300%;
    -webkit-animation: gradient 8s ease infinite;
            animation: gradient 8s ease infinite;
    color: white;
    padding: 1rem;
  }
  
  .flip-card-back-prize {
    background: black;
    border: 2px solid white;
    color: white;
    padding: 3rem;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    font-family: 'DM Sans', sans-serif;
  }
  
  .header2 {
    position: relative;
    overflow: hidden;
  }
  
  .header2::before {
    position: absolute;
    top: 75%;
    right:15%;
    overflow: hidden;
    width: 80rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    -webkit-animation: gradient 5s ease infinite;
            animation: gradient 5s ease infinite;
  }
  
  @media only screen and (max-width: 1200px)
  {
    .header2 {
      text-align: center !important;
    }
    .header2::before {
        display: none;
    }
  }
  
  @-webkit-keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .mainhead1-prize {
    
    color: white;
    font-size: 5vmax;
    text-align: center;
    /* font-weight: 600 !important; */
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-65%);
            transform: translateY(-65%);
  }

  .sawo {
    color: black;
  }

  .mainhead2-prize {
    text-align: center;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  
  .svg-img {
  position: absolute;
  bottom: -10%;
  right: 0;
  }
  
  
  .svg-img1 {
  position: absolute;
  bottom: -10%;
  right: -15%;
  }
  
  @-webkit-keyframes shine {
    0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
  }
  
  @keyframes shine {
    0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
  }
  
  
  @media only screen and (max-width: 786px)
  {
  .flip-card-prize:active .flip-card-inner-prize {
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg);
    }
  
  .flip-card-back-prize h4 {
    font-size: 1.2rem;
  }
  
  .flip-card-back-prize {
    padding: 2rem;
  }
  }
.faq {
    background-color: black;
    height: 100%;
    font-family: 'DM Sans', sans-serif;
}

.accordion {
    padding: 1rem;
    text-align: left;
    font-size: 24px;
    cursor: pointer;
}

.card-acc {
    border: none;
}

.card-body-acc {
    color: white;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 300% 300%;
    -webkit-animation: gradient 5s ease infinite;
            animation: gradient 5s ease infinite;
}

.acc-toggle {
    background-color: black;
    color: white;
}
.faq-text {
  position: relative;
  overflow: hidden;
}

.faq-text::after {
  position: absolute;
  top: 75%;
  left:12%;
  overflow: hidden;
  width: 70rem;
  height: 3px;
  content: '\a0';
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 200% 200%;
  -webkit-animation: gradient 5s ease infinite;
          animation: gradient 5s ease infinite;
}

.org-header {
  position: relative;
  overflow: hidden;
}

.org-header::before {
  position: absolute;
  top: 50%;
  right:23%;
  overflow: hidden;
  width: 50rem;
  height: 3px;
  content: '\a0';
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 200% 200%;
  -webkit-animation: gradient 5s ease infinite;
          animation: gradient 5s ease infinite;
}

@media only screen and (max-width: 1200px)
{
  .org-header {
      text-align: center !important;
  }
  .org-header::before {
      display: none;
  }
}


@media only screen and (max-width: 1200px)
{
  .faq-text {
      text-align: center !important;
  }
  .faq-text::after {
      display: none;
  }
}


@-webkit-keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }


@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

@media only screen and (max-width: 786px) {
    .accordion {
        padding: 2rem 0;
        font-size: 16px;
    }
}
h3{
    color: aliceblue;
}

p{
    color:gainsboro;
}

i{
    color: aliceblue;
}

#copyright{
    height: 50px;
    background-color: aliceblue;
}

.mdb-col {
    padding: 2rem 0;
    background-color: black;
}
.player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }


.jm-text {
    position: relative;
    overflow: hidden;
  }
  
  .jm-text::after {
    position: absolute;
    top: 50%;
    left:40%;
    overflow: hidden;
    width: 70rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    -webkit-animation: gradient 5s ease infinite;
            animation: gradient 5s ease infinite;
  }
  
@media only screen and (max-width: 1200px)
{
  .jm-text{
    text-align: center !important;
  }
  .jm-text::after {
    display: none;
  }
}


.speak-col {
  width: 10%;
}

.speak-logos {
  width: 230px;
  border-radius: 3rem;
  margin: 1rem 0;
}

.speak-logos:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.2s ease;
}

.jm h2 {
  color: #fff;
}

.jm h5 {
  color: #fff;
}
.header3 {
    position: relative;
    overflow: hidden;
  }
  
  .header3::before {
    position: absolute;
    top: 50%;
    left:20%;
    overflow: hidden;
    width: 70rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    -webkit-animation: gradient 5s ease infinite;
            animation: gradient 5s ease infinite;
  }
  
  @media only screen and (max-width: 1200px)
  {
    .header3 {
        text-align: center !important;
    }
    .header3::before {
        display: none;
    }
  }
  
