.oc-img {
    border-radius: 100%;
}
#oclogo{
    height: 150px;
}

.octitle {
  font-size: 4vmax;
  background: linear-gradient(
    -45deg,
    #ee7752 20%,
    #e73c7e 40%,
    #23a6d5 60%,
    #23d5ab 80%
  );
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 6s ease infinite;
}

/* .speak-text {
    position: relative;
    overflow: hidden;
  }
  
  .speak-text::before {
    position: absolute;
    top: 50%;
    right:20%;
    overflow: hidden;
    width: 70rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    animation: gradient 5s ease infinite;
  }
  
  @media only screen and (max-width: 1200px)
  {
    .speak-text {
        text-align: center !important;
    }
    .speak-text::before {
        display: none;
    }
  }
  */