.navbar {
  background-color: rgb(0, 0, 0, 0.6);
  backdrop-filter: blur(15px);
  color: rgb(207, 207, 207);
}

.nav-links {
  margin-right: 3rem;
}

.nav-toggles {
  color: white !important;
}

.nav-toggles:hover {
    transform: scale(1.1);
    transition: 0.2s ease;
}

@media only screen and (max-width: 786px)
{
    .nav-name {
        display: none;
    }

  .nav-links {
    margin-right: unset;
  }

  .toggler {
    border: none;
  }
}
