.flip-card {
  background-color: transparent;
  width: 100%;
  height: 400px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 20px;
}

.flip-card-front {
  background-color: #000000;
  color: white;
  padding: 3rem;
}

.flip-card-back {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 300% 300%;
  animation: gradient 5s ease infinite;
  color: white;
  padding: 3rem;
  transform: rotateY(180deg);
  font-family: 'DM Sans', sans-serif;
}

.header2 {
  position: relative;
  overflow: hidden;
}

.header2::before {
  position: absolute;
  top: 75%;
  right:15%;
  overflow: hidden;
  width: 80rem;
  height: 3px;
  content: '\a0';
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
}

.headeriti {
  position: relative;
  overflow: hidden;
}

.headeriti::before {
  position: absolute;
  top: 75%;
  right:48%;
  overflow: hidden;
  width: 80rem;
  height: 3px;
  content: '\a0';
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
}

@media only screen and (max-width: 1200px)
{
  .header2 {
    text-align: center !important;
  }
  .header2::before {
      display: none;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.mainhead1 {
  
  background: linear-gradient(-45deg, #ee7752 20%, #e73c7e 40%, #23a6d5 60%, #23d5ab 80%);
  background-size: 200% auto;
  
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  animation: shine 6s ease infinite;
}

.svg-img {
position: absolute;
bottom: -10%;
right: 0;
}


.svg-img1 {
position: absolute;
bottom: -10%;
right: -15%;
}

@keyframes shine {
  0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
}


@media only screen and (max-width: 786px)
{
.flip-card:active .flip-card-inner {
    transform: rotateY(180deg);
  }

.flip-card-back h4 {
  font-size: 1.2rem;
}

.flip-card-back {
  padding: 2rem;
}
}