.faq {
    background-color: black;
    height: 100%;
    font-family: 'DM Sans', sans-serif;
}

.accordion {
    padding: 1rem;
    text-align: left;
    font-size: 24px;
    cursor: pointer;
}

.card-acc {
    border: none;
}

.card-body-acc {
    color: white;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 300% 300%;
    animation: gradient 5s ease infinite;
}

.acc-toggle {
    background-color: black;
    color: white;
}
.faq-text {
  position: relative;
  overflow: hidden;
}

.faq-text::after {
  position: absolute;
  top: 75%;
  left:12%;
  overflow: hidden;
  width: 70rem;
  height: 3px;
  content: '\a0';
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
}

.org-header {
  position: relative;
  overflow: hidden;
}

.org-header::before {
  position: absolute;
  top: 50%;
  right:23%;
  overflow: hidden;
  width: 50rem;
  height: 3px;
  content: '\a0';
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
}

@media only screen and (max-width: 1200px)
{
  .org-header {
      text-align: center !important;
  }
  .org-header::before {
      display: none;
  }
}


@media only screen and (max-width: 1200px)
{
  .faq-text {
      text-align: center !important;
  }
  .faq-text::after {
      display: none;
  }
}


@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

@media only screen and (max-width: 786px) {
    .accordion {
        padding: 2rem 0;
        font-size: 16px;
    }
}