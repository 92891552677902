h3{
    color: aliceblue;
}

p{
    color:gainsboro;
}

i{
    color: aliceblue;
}

#copyright{
    height: 50px;
    background-color: aliceblue;
}

.mdb-col {
    padding: 2rem 0;
    background-color: black;
}