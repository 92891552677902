.prizes-text {
    position: relative;
    overflow: hidden;
  }

  .tba {
      font-size: 4rem;
      padding: 4rem;
  }
  
  .prizes-text::after {
    position: absolute;
    top: 50%;
    right:12%;
    overflow: hidden;
    width: 70rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    animation: gradient 5s ease infinite;
  }
  
  @media only screen and (max-width: 1200px)
  {
    .prizes-text {
        text-align: center !important;
    }
    .prizes-text::after {
        display: none;
    }
    .tba {
      text-align: center;
      font-size: 3.5rem;
      padding: 0;
    }
  }
  
  .flip-card-prize {
    background-color: transparent;
    width: 100%;
    height: 400px;
    perspective: 1000px;
  }
  
  .flip-card-inner-prize {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: left;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card-prize:hover .flip-card-inner-prize {
    transform: rotateY(180deg);
  }
  
  .flip-card-front-prize, .flip-card-back-prize, .flip-card-front-sawo, .flip-card-front-raahee {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 20px;
  }
  
  .flip-card-front-prize {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 300% 300%;
    animation: gradient 8s ease infinite;
    color: white;
    padding: 1rem;
  }

  .flip-card-front-sawo {
    background: #fcde50;
    background-size: 300% 300%;
    animation: gradient 8s ease infinite;
    color: white;
    padding: 1rem;
  }
  
  .flip-card-front-raahee {
    background: linear-gradient(135deg, rgba(56,29,89,1) 35%, rgba(252,139,139,1) 71%, rgba(252,195,112,1) 100%);
    background-size: 300% 300%;
    animation: gradient 8s ease infinite;
    color: white;
    padding: 1rem;
  }
  
  .flip-card-back-prize {
    background: black;
    border: 2px solid white;
    color: white;
    padding: 3rem;
    transform: rotateY(180deg);
    font-family: 'DM Sans', sans-serif;
  }
  
  .header2 {
    position: relative;
    overflow: hidden;
  }
  
  .header2::before {
    position: absolute;
    top: 75%;
    right:15%;
    overflow: hidden;
    width: 80rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    animation: gradient 5s ease infinite;
  }
  
  @media only screen and (max-width: 1200px)
  {
    .header2 {
      text-align: center !important;
    }
    .header2::before {
        display: none;
    }
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .mainhead1-prize {
    
    color: white;
    font-size: 5vmax;
    text-align: center;
    /* font-weight: 600 !important; */
    position: absolute;
    top: 50%;
    transform: translateY(-65%);
  }

  .sawo {
    color: black;
  }

  .mainhead2-prize {
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .svg-img {
  position: absolute;
  bottom: -10%;
  right: 0;
  }
  
  
  .svg-img1 {
  position: absolute;
  bottom: -10%;
  right: -15%;
  }
  
  @keyframes shine {
    0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
  }
  
  
  @media only screen and (max-width: 786px)
  {
  .flip-card-prize:active .flip-card-inner-prize {
      transform: rotateY(180deg);
    }
  
  .flip-card-back-prize h4 {
    font-size: 1.2rem;
  }
  
  .flip-card-back-prize {
    padding: 2rem;
  }
  }