.register {
    height: 100vh;
    background-color: black;
    color: white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /*added to prevent user select on the main hero/landing viewport */
  }
  
  .particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
  }
  
  .register-title{
    font-size: 3vmax;
    color: aliceblue;
  }

  .mainhead {
    font-size: 8vmax;
    background: linear-gradient(
      -45deg,
      #ee7752 20%,
      #e73c7e 40%,
      #23a6d5 60%,
      #23d5ab 80%
    );
    background-size: 200% auto;
  
    color: #000;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  
    animation: shine 6s ease infinite;
  }
  
  .btn {
      border: 2px solid white;
  }
  
  @keyframes shine {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .date {
    font-size: 3vmax;
  }
  