.text-gold {
    
    background: linear-gradient(-45deg, #fff 20%, #0084ff 40%, #fff 60%, #0084ff 80%);
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 8s ease infinite;
    font-size: 4rem;
  }

.text-silver {
    background: linear-gradient(-45deg, #fff 20%, #777777 40%, #fff 60%, #777777 80%);
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 8s ease infinite;
    font-size: 4rem;
}
  
.text-bronze {
    background: linear-gradient(-45deg, #fff 10%, #ffee00 40%, #fff 60%, #ffee00 80%);
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 8s ease infinite;
    font-size: 4rem;
}

.text-bronze-real {
  background: linear-gradient(-45deg, #fff 10%, #CD7F32 40%, #fff 60%, #CD7F32 80%);
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 8s ease infinite;
  font-size: 4rem;
}

.text-partner {
  color: #fff;
  font-size: 4rem;
}

.spon-col {
  
}

.spon-logos {
  /* width: 100%; */
  height: 200px;
  border-radius: 1rem;
  margin: 1rem 0;
}

.spon-logos:hover {
  transform: scale(1.1);
  transition: 0.2s ease;
}

.sponsors h2 {
  color: #fff;
}

.header3 {
    position: relative;
    overflow: hidden;
  }
  
  .header3::before {
    position: absolute;
    top: 50%;
    left:20%;
    overflow: hidden;
    width: 70rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    animation: gradient 5s ease infinite;
  }
  
  @media only screen and (max-width: 1200px)
  {
    .header3 {
        text-align: center !important;
    }
    .header3::before {
        display: none;
    }
  }

  @media only screen and (max-width: 786px) {
    .spon-logos {
      height: 150px;
    }
  }
  